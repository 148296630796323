// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
const firebaseConfig = {
    apiKey: "AIzaSyAC1HBXuVZ7sxP_uISbed5M7JQGD08c4i4",
    authDomain: "salami-f8596.firebaseapp.com",
    projectId: "salami-f8596",
    storageBucket: "salami-f8596.appspot.com",
    messagingSenderId: "342036508552",
    appId: "1:342036508552:web:ace8033045c2a18750543d",
    measurementId: "G-7DYE6R0Y4R"
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  
  export { db, auth };
  
