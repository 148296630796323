import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Button, Card, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { db } from '../firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import emailjs from '@emailjs/browser';

const PaymentForm = () => {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [method, setMethod] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [personalizedMessages, setPersonalizedMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      const querySnapshot = await getDocs(collection(db, "personalizedMessages"));
      const messagesList = querySnapshot.docs.map(doc => ({ name: doc.data().name.toLowerCase(), message: doc.data().message }));
      setPersonalizedMessages(messagesList);
    };

    fetchMessages();
  }, []);

  const paymentAddresses = {
    Bkash: "01762342949",
    Nagad: "01762342949",
    Cash: "Meet me at Bhaighat"
  };

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    alert('Address copied to clipboard');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sending email using EmailJS
    const templateParams = {
      name: name,
      amount: amount,
      method: method,
      transactionId: transactionId,
      phoneNumber: phoneNumber
    };

    emailjs.send('service_oix5x3d', 'template_qefasx8', templateParams, 'dYiMYm1QkJRnePLAt')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      }, (error) => {
        console.log('FAILED...', error.text);
      });

    try {
      await addDoc(collection(db, "payments"), {
        name,
        amount,
        method,
        transactionId,
        phoneNumber,
      });
      setSubmitted(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const getPersonalizedMessage = () => {
    const personalizedMessage = personalizedMessages.find(msg => msg.name === name.toLowerCase());
    return personalizedMessage ? personalizedMessage.message : 'Eid Mubarak';
  };

  return (
    <Card>
      <Card.Header className="text-center">
        <h1 className="eid-header">{getPersonalizedMessage()}</h1>
        <div className="header-decoration">
          <div className="square"></div>
          <div className="square red"></div>
          <div className="square yellow"></div>
          <div className="square green"></div>
        </div>
      </Card.Header>
      <Card.Body>
        {!submitted ? (
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Enter your name:</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAmount">
                  <Form.Label>Enter amount:</Form.Label>
                  <Form.Control
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formMethod">
                  <Form.Label>Select payment method:</Form.Label>
                  <Form.Control
                    as="select"
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                    required
                  >
                    <option value="" disabled>Select</option>
                    <option value="Bkash">Bkash</option>
                    <option value="Nagad">Nagad</option>
                    <option value="Cash">Cash</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPaymentAddress">
                  <Form.Label>Payment Address:</Form.Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      value={paymentAddresses[method] || ''}
                      readOnly
                    />
                    <Button variant="outline-secondary" onClick={() => handleCopy(paymentAddresses[method] || '')}>
                      Copy
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formTransactionId">
                  <Form.Label>Transaction ID:</Form.Label>
                  <Form.Control
                    type="text"
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPhoneNumber">
                  <Form.Label>Phone Number:</Form.Label>
                  <Form.Control
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit" className="mt-3">Complete Payment</Button>
          </Form>
        ) : (
          <div className="text-center">
            <h2>Payment Successful</h2>
            <p>Thank you, {name}, for your payment of {amount} via {method}.</p>
            <p>Please complete the payment at the following address: {paymentAddresses[method]}</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default PaymentForm;
