import React, { useState, useEffect } from 'react';
import { Container, Table, Card, Form, Button, Row, Col } from 'react-bootstrap';
import { db } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';

const AdminDashboard = () => {
  const [payments, setPayments] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newName, setNewName] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [editMessageId, setEditMessageId] = useState(null);
  const [editName, setEditName] = useState('');
  const [editMessage, setEditMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState('');
  const [userNumber, setUserNumber] = useState('');

  useEffect(() => {
    const fetchPayments = async () => {
      const querySnapshot = await getDocs(collection(db, "payments"));
      const paymentsList = querySnapshot.docs.map(doc => doc.data());
      setPayments(paymentsList);
    };

    const fetchMessages = async () => {
      const querySnapshot = await getDocs(collection(db, "personalizedMessages"));
      const messagesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(messagesList);
    };

    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersList);
    };

    fetchPayments();
    fetchMessages();
    fetchUsers();
  }, []);

  const handleAddMessage = async () => {
    if (newName && newMessage) {
      await addDoc(collection(db, "personalizedMessages"), {
        name: newName,
        message: newMessage,
      });
      setNewName('');
      setNewMessage('');
      const querySnapshot = await getDocs(collection(db, "personalizedMessages"));
      const messagesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(messagesList);
    }
  };

  const handleEditMessage = async () => {
    if (editMessageId && editName && editMessage) {
      const messageDoc = doc(db, "personalizedMessages", editMessageId);
      await updateDoc(messageDoc, {
        name: editName,
        message: editMessage,
      });
      setEditMessageId(null);
      setEditName('');
      setEditMessage('');
      const querySnapshot = await getDocs(collection(db, "personalizedMessages"));
      const messagesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(messagesList);
    }
  };

  const handleEditClick = (id, name, message) => {
    setEditMessageId(id);
    setEditName(name);
    setEditMessage(message);
  };

  const handleAddUser = async () => {
    if (userName && userNumber) {
      await addDoc(collection(db, "users"), {
        name: userName,
        number: userNumber,
      });
      setUserName('');
      setUserNumber('');
      const querySnapshot = await getDocs(collection(db, "users"));
      const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersList);
    }
  };

  const sendwhatsapp = (name, number) => {
    const formattedNumber = `+880${number}`;
    const url = `https://wa.me/${formattedNumber}?text=${encodeURIComponent(
      `*Eid Mubrak,ঈদ এর চাঁদ আকাশে সালামী দিন বিকাশ এ 💸*\n\n Salami Din www.salami.musuuu.com`
    )}`;

    window.open(url, '_blank').focus();
  };

  return (
    <Container className="mt-5">
      <Card>
        <Card.Header className="text-center">
          <h1>Admin Dashboard</h1>
        </Card.Header>
        <Card.Body>
          <h2>Payments</h2>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Method</th>
                  <th>Transaction ID</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment, index) => (
                  <tr key={index}>
                    <td>{payment.name}</td>
                    <td>{payment.amount}</td>
                    <td>{payment.method}</td>
                    <td>{payment.transactionId}</td>
                    <td>{payment.phoneNumber}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <h2>Personalized Messages</h2>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Message</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {messages.map((msg, index) => (
                  <tr key={index}>
                    <td>{msg.name}</td>
                    <td>{msg.message}</td>
                    <td>
                      <Button variant="warning" onClick={() => handleEditClick(msg.id, msg.name, msg.message)}>Edit</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <h2>Add New Message</h2>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Message</Form.Label>
                  <Form.Control type="text" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" onClick={handleAddMessage}>Add Message</Button>
          </Form>

          {editMessageId && (
            <>
              <h2>Edit Message</h2>
              <Form>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" value={editName} onChange={(e) => setEditName(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Message</Form.Label>
                      <Form.Control type="text" value={editMessage} onChange={(e) => setEditMessage(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" onClick={handleEditMessage}>Update Message</Button>
              </Form>
            </>
          )}

          <h2>Users</h2>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Number</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td>{user.name}</td>
                    <td>{user.number}</td>
                    <td>
                      <Button variant="success" onClick={() => sendwhatsapp(user.name, user.number)}>Send Salami Request</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <h2>Add New User</h2>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" value={userName} onChange={(e) => setUserName(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Number</Form.Label>
                  <Form.Control type="text" value={userNumber} onChange={(e) => setUserNumber(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" onClick={handleAddUser}>Add User</Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AdminDashboard;
